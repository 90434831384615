import { toast } from "react-toastify";
import api from "../../api";
import FontPicker from "components/FontPicker";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
// core components

const EditHomeScreen = ({ openModal, handleModal, home, setHome, getHome }) => {
  const handleInput = (e) => {
    setHome({ ...home, [e.target.name]: e.target.value });
  };
  const handleSwitch = (e) => {
    setHome({ ...home, is_background_image_active: e.target.checked });
  };
  const handleFileChange = (e) => {
    setHome({ ...home, [e.target.name]: "" });

    const formData = new FormData();
    formData.append("image", e.target.files[0]);
    api("post", "/image", formData).then((res) => {
      toast.success("Image uploaded successfully");
      setHome({ ...home, [e.target.name]: res.url });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!(home.text && home.yt_link)) {
      return toast.error("All fields are required");
    }

    if (!home.image) {
      return toast.error("Wait before the image to upload");
    }

    api("post", "/homeScreen", home).then((res) => {
      toast.success("home edited successfully");
      getHome();
      handleModal();
    });
  };

  return (
    <>
      <Modal isOpen={openModal} size="xl" centered>
        <ModalHeader charCode="X" toggle={handleModal}>
          Edit home
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="Image">
                          <span style={{ color: "red" }}>
                            {home.image ? "" : "*"}
                          </span>{" "}
                          Image <i className="ni ni-album-2" />
                        </label>
                        <Input
                          className="form-control-file form"
                          id="Image"
                          name="image"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="form-control-label" htmlFor="Image">
                          Background Image <i className="ni ni-album-2" />
                        </label>
                        <Input
                          className="form-control-file form"
                          id="BackgroundImage"
                          name="background_image"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id={`customCheck1`}
                          checked={home.is_background_image_active}
                          className="custom-control-input"
                          onChange={handleSwitch}
                        />
                        <label
                          class="custom-control-label"
                          for={`customCheck1`}
                        >
                          Background Image Active
                        </label>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="8" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {home.text ? "" : "*"}{" "}
                          </span>
                          Text
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter home's text here"
                          type="text"
                          value={home.text}
                          name="text"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                    <FontPicker
                      data={home}
                      setData={setHome}
                      fieldName="text_font"
                    />
                  </Row>
                  <Row>
                    <Col lg="12" style={{ margin: "auto" }}>
                      <FormGroup>
                        <label className="form-control-label">
                          {" "}
                          <span style={{ color: "red" }}>
                            {home.yt_link ? "" : "*"}{" "}
                          </span>
                          Youtube URL
                        </label>{" "}
                        <Input
                          className="form-control-alternative text-default"
                          required={true}
                          placeholder="Enter Youtube URL here"
                          type="text"
                          value={home.yt_link}
                          name="yt_link"
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            Update
          </Button>

          <Button onClick={handleModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditHomeScreen;
